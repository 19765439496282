import { Typography, Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { changePassword } from "../../redux";

const NewPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, 
  //  setLoading
  ] = useState(false);
  const { Title } = Typography;
  const onFinish = async (values) => {
    console.log(values);
    console.log(localStorage.getItem("userType"));
    const payload = {
      newPassword: values.new,
      confirmPassword: values.confirm,
      recoveryToken: localStorage.getItem("recoveryToken"),
      email: localStorage.getItem("email"),
      userType: localStorage.getItem("userType"),
    };
    dispatch(changePassword(payload, history));
  };

  return (
    <>
      <Title level={4} className="title">
        Set New Password
      </Title>
      <Form
        name="normal_login"
        className="login-form"
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="new"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="New Password"
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            Change password
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewPassword;
