import { useState } from "react";
import { Form, Input, Button } from "antd";
import { Link, useHistory, Redirect } from "react-router-dom";
import logo from "../assets/qrlogo.png";
import { forgetPassword } from "../redux";
import { useDispatch } from "react-redux";

const ForgetPassword = () => {
  //   const { adminId, tableId } = useParams();
  //   console.log(adminId, tableId);

  const history = useHistory();

  const [loading, 
    //setLoading
  ] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    // setLoading(true);
    console.log(values.email);
    const payload = {
      userType: "superAdmin",
      email: values.email,
    };
    console.log(payload);
    dispatch(forgetPassword(payload, history));
  };

  if (localStorage.hasOwnProperty("superAdminId")) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="signin">
        <Link to="/">
          <img src={logo} alt="logo" width="auto" height="100px" />
          {/* <p>QRTREAT</p> */}
        </Link>
        <Form
          name="login"
          className="login-form"
          layout="vertical"
          onFinish={onFinish}
        >
          <h2>Forget Password</h2>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              autoComplete="off"
              placeholder="Enter Email..."
              type="email"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
};

export default ForgetPassword;
