import { Modal, Button, Typography, Row, Col, Tag } from "antd";
import moment from "moment";
import { useState } from "react";

const ViewDetails = ({ orderDetails }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    //loading
    , 
    setLoading] = useState(false);


  const { Title } = Typography;

  const showModal = async () => {
    setLoading(true);
    console.log(orderDetails);

    setIsModalVisible(true);
    // if (type === "admin") {
    //   const adminDetails = await dispatch(getAdminDetails(id));
    //   setDetails(adminDetails);

    //   let totalAmount = 0;
    //   adminDetails &&
    //     adminDetails.length > 0 &&
    //     adminDetails.map((total) => {
    //       return (totalAmount += total.amount);
    //     });
    //   setTotal(totalAmount);
    //   setLoading(false);
    // } else {
    //   const userDetails = await dispatch(getUserDetails(id));
    //   setDetails(userDetails);

    //   console.log(userDetails);

    //   let totalAmount = 0;
    //   userDetails &&
    //     userDetails.length > 0 &&
    //     userDetails.map((total) => {
    //       return (totalAmount += total.amount);
    //     });
    //   setTotal(totalAmount);
    //   setLoading(false);
    // }

    // // console.log(details);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const columns = [
  //   {
  //     title: "Created At",
  //     dataIndex: "createdAt",
  //     width: 80,
  //   },
  //   {
  //     title: "Products",
  //     dataIndex: "products",
  //     width: 300,
  //   },
  //   {
  //     title: "Quantity",
  //     dataIndex: "quantity",
  //     width: 100,
  //   },
  //   {
  //     title: "Amount",
  //     dataIndex: "amount",
  //     sorter: (a, b) => a.amount.length - b.amount.length,
  //     width: 100,
  //   },
  //   {
  //     title: "Order Status",
  //     dataIndex: "status",
  //     sorter: (a, b) => a.status.length - b.status.length,
  //     width: 100,
  //   },
  // ];

  // const getProductNames = (products, type) => {
  //   let names = [];
  //   let quantity = 0;
  //   console.log(products);
  //   products &&
  //     products.length > 0 &&
  //     products.map((item) => {
  //       names.push(item.product ? item.product.name : "");
  //       quantity += item.quantity;
  //     });
  //   return type === "name" ? names.join(",") : quantity;
  // };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        View
      </Button>

      <Modal
        width={"80%"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        maskClosable={false}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          Order Details
        </Title>
        <Row gutter={[30, 30]} justify="center" className="admin-details">
          <Col xs={24} md={12}>
            <p>
              <span className="admin-detail">Customer Name : </span>
              {orderDetails.user ? orderDetails.user.name : "-"}
            </p>
          </Col>
          <Col xs={24} md={12}>
            <p>
              <span className="admin-detail">Customer Contact : </span>
              {orderDetails.user ? orderDetails.user.mobile : "-"}
            </p>
          </Col>
          <Col xs={24} md={12}>
            <p>
              <span className="admin-detail">Total Bill : </span>
              {orderDetails.amount}
            </p>
          </Col>
          <Col xs={24} md={12}>
            <p>
              <span className="admin-detail">Order Time : </span>
              {moment(orderDetails.createdAt).format("MMM Do YY, h:mm:ss a")}
            </p>
          </Col>
        </Row>
        {orderDetails &&
          orderDetails?.products.map((details) => {
            return (
              <div className="order-details">
                <Row gutter={[30, 30]}>
                  <Col xs={24} md={12} lg={8}>
                    <Title level={5}>
                      <span className="product-details">Name : </span>
                      {details.product ? details.product.name : ""}
                    </Title>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Title level={5}>
                      <span className="product-details">Quantity : </span>
                      {details.quantity}
                    </Title>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <Title level={5}>
                      <span className="product-details">Per Price : </span>
                      {details.product ? details.product.price : ""}
                    </Title>
                  </Col>
                  {details.extraProducts.length > 0 ? (
                    <Col xs={24}>
                      <Title level={5}>
                        <span className="product-details">
                          Extra Products :{" "}
                        </span>
                        {details.extraProducts.map((ep, i) => {
                          return (
                            <Tag key={i} color="pink">
                              {ep.details.name}
                            </Tag>
                          );
                        })}
                      </Title>
                    </Col>
                  ) : (
                    ""
                  )}
                  {details.extraProducts.length > 0 ? (
                    <Col xs={24}>
                      {" "}
                      <Title level={5}>
                        <span className="product-details">Allergies : </span>
                        {details.allergicNames.map((a, i) => {
                          return (
                            <Tag key={i} color="orange">
                              {a.allergicName}
                            </Tag>
                          );
                        })}
                      </Title>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </div>
            );
          })}
      </Modal>
    </>
  );
};

export default ViewDetails;
