import { useEffect } from 'react';
import 'antd/dist/antd.less';

import Routes from "./Routes";
import theme from './theme.json';
import "./styles/style.css";

const App = () => {

  useEffect(() => {
    Object.keys(theme).forEach(key => {
      document.body.style.setProperty(`--${key}`, theme[key]);
    });
  }, []);

  return <Routes />;
}

export default App;
