import { Layout, Menu, Dropdown, Avatar } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import qrlogo from "../assets/qrlogo.png";
import LayoutMenu from "./LayoutMenu";

const { Header, Content, Sider } = Layout;

const LayoutDesktop = ({ children, active }) => {
  const history = useHistory();
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<LogoutOutlined />}
        onClick={() => {
          localStorage.removeItem("superAdminId");
          localStorage.removeItem("token");
          history.replace("/login");
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="layoutDesktop">
      <Layout style={{ minHeight: "100vh" }}>
        <Sider>
          <div className="logo">
            <img
              src={qrlogo}
              alt="qrlogo"
              height="70px"
              width="70px"
              className="logoImg"
            />
          </div>
          <LayoutMenu active={active} />
        </Sider>

        <Layout className="site-layout">
          <Header className="site-layout-background header">
            {/* <div className="header"> */}
            <p>QR TREAT</p>
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar
                  style={{ backgroundColor: "#f0a7d3" }}
                  icon={<UserOutlined />}
                />
              </a>
            </Dropdown>
            {/* </div> */}
          </Header>
          <Content>
            <div style={{ padding: 24, minHeight: 360 }}>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default LayoutDesktop;
