import * as adminTypes from "../types/adminTypes";

const initialState = {
  allAdmins: [],
  admins: [],
};

const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case adminTypes.GET_ALL_ADMINS:
      return {
        ...state,
        allAdmins: payload,
      };
    case adminTypes.SEARCH_ADMIN:
      let searchedAdmin = payload;
      let newUser = state.allAdmins.filter((user) => {
        return user.name.toLowerCase().includes(searchedAdmin);
      });
      return {
        ...state,
        allAdmins: newUser,
      };
    case adminTypes.GET_ADMINS:
      return {
        ...state,
        admins: payload,
      };

    default:
      return state;
  }
};

export default adminReducer;
