import axios from "axios";

import { url, headers } from "../../constants";
import * as userTypes from "../types/userTypes";
import swal from "sweetalert";

export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/superAdmin/get-all-users`, {
      headers,
    });
    if (res) {
      // return res.data.users;
      dispatch({
        type: userTypes.GET_ALL_USERS,
        payload: res.data.users,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/superAdmin/get-users`, {
      headers,
    });

    if (res) {
      // return res.data.users;
      dispatch({
        type: userTypes.GET_USERS,
        payload: res.data.users,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getUserDetails = (user) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${url}/superAdmin/get-user-details`,
      {
        user,
      },
      {
        headers,
      }
    );
    console.log("user chala", res.data);
    if (res) {
      return res.data.orders;
    }
  } catch (err) {
    console.log(err);
  }
};
export const searchUser = (payload) => (dispatch) => {
  console.log(payload);
  if (payload === "") {
    dispatch(getAllUsers());
  } else {
    dispatch({
      type: userTypes.SEARCH_USER,
      payload: payload.toLowerCase(),
    });
  }
};

export const deactivateUser = (userId) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/superAdmin/deactivate-user`,
        {
          userId,
        },
        {
          headers,
        }
      );
      if (res) {
        dispatch(getAllUsers());
        swal("", res.data.message, "success");
        // window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };
};
