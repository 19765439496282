import { combineReducers } from "redux";

import adminReducer from "./reducers/adminReducer";
import userReducer from "./reducers/userReducer";

const rootReducer = combineReducers({
  adminReducer,
  userReducer,
});

export default rootReducer;
