import { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { useDispatch } from "react-redux";
// import swal from 'sweetalert'
import Layout from "../layout/DashboardLayout";
import {
  createAdmin,
  getAllCitiesbyCountry,
  getAllCountries,
  getAllCurrencies,
} from "../redux";

const CreateAdmin = () => {
  // const countries = useSelector((state) => state.generalReducer.allCountries);
  // const cities = useSelector((state) => state.generalReducer.allCities);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log(values);
    setLoading(true);
    // if (
    //   values.fullName !== undefined &&
    //   values.fullName !== "" &&
    //   values.email !== undefined &&
    //   values.email !== "" &&
    //   values.city !== undefined &&
    //   values.city !== "" &&
    //   values.country !== undefined &&
    //   values.country !== "" &&
    //   values.suburb !== undefined &&
    //   values.suburb !== "" &&
    //   values.password !== undefined &&
    //   values.password !== "" &&
    //   values.currency !== undefined &&
    //   values.currency !== ""
    // ) {
    console.log("hi");
    const payload = {
      name: values.fullName,
      email: values.email,
      city: values.city,
      country: values.country,
      suburb: values.city,
      password: values.password,
      currency: values.currency,
      paymentSystem: "No",
    };
    // console.log(payload);
    await dispatch(createAdmin(payload));
    setLoading(false);
    // form.resetFields();

    // } else {
    //   swal({
    //     title: "Incomplete Information",
    //     icon: "error",
    //   });
    // }
  };

  const onCountryChange = async (value) => {
    const cities = await dispatch(getAllCitiesbyCountry(value));
    setCities(cities);
  };

  useEffect(async () => {
    const countries = await dispatch(getAllCountries());
    let Currencies = await dispatch(getAllCurrencies());
    setCountries(countries);
    setCurrencies(Currencies);
  }, []);

  console.log(currencies);

  // if (localStorage.hasOwnProperty("adminEmail")) {
  //   return <Redirect to="/" />;
  // } else {
  return (
    <Layout className="signin" active={"create"}>
      <Form
        name="login"
        className="login-form"
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <h2>Create Admin</h2>
        <Form.Item
          name="fullName"
          label="Full Name"
          rules={[
            {
              required: true,
              message: "Please input your Name!",
            },
          ]}
        >
          <Input autoComplete="off" placeholder="Enter Full Name..." />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input autoComplete="off" placeholder="Enter Email..." type="email" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password type="password" placeholder="Password..." />
        </Form.Item>
        <Form.Item
          label="Select Country"
          name="country"
          rules={[
            {
              required: true,
              message: "Please Select Country!",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            style={{ width: "100%" }}
            // placeholder={userInfo.country}
            optionFilterProp="children"
            onChange={onCountryChange}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countries &&
              countries.map((country, i) => {
                return (
                  <Select.Option key={i} value={country}>
                    {country}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Select City/District"
          name="city"
          rules={[
            {
              required: true,
              message: "Please Select City!",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            style={{ width: "100%" }}
            // placeholder={userInfo.city}
            optionFilterProp="children"
            // onChange={onCountryChange}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {cities &&
              cities.length > 0 &&
              cities.map((city) => {
                return (
                  <Select.Option key={city._id} value={city.city}>
                    {city.city}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="currency"
          label="Currency (currency can not be changed later)"
          rules={[
            {
              required: true,
              message: "Please Select Your currency!",
            },
          ]}
        >
          <Select
            size="large"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
            allowClear
          >
            {currencies &&
              currencies.map((currency, i) => {
                return (
                  <Select.Option key={i} value={currency.symbol}>
                    {`${currency.name} ( ${currency.symbol} )`}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="suburb"
          label="Suburb"
          rules={[
            {
              required: true,
              message: "Suburb Required!",
            },
          ]}
        >
          <Input placeholder="Suburb..." />
        </Form.Item> */}
        <Form.Item name="payment" label="Payment System">
          <Input placeholder="NO" disabled={true} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
  // }
};

export default CreateAdmin;
