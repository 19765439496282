import CodeVerification from "../components/veirifcation/CodeVerification";
import NewPassword from "../components/veirifcation/NewPassword";

const VerifyCode = () => {
  

  return (
    <div className="login">
      <div className="login-container">
        <CodeVerification />
        <NewPassword />
      </div>
    </div>
  );
};

export default VerifyCode;
