import { useEffect } from "react";
import { Row, Col, Typography, Table } from "antd";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Layout from "../layout/DashboardLayout";
import { getAdminDetails, getUserDetails } from "../redux";
import { useState } from "react";
import moment from "moment";
import ViewDetails from "../components/ViewDetails";

const AdminDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { Title } = Typography;
  const [selected, setSelected] = useState(null);
  const [type, setType] = useState("");
  const [userInfo, setUserInfo] = useState(null);

  console.log(location.state.id);
  console.log(location.state.type);
  console.log(location.state.details);

  useEffect(async () => {
    setUserInfo(location.state.details);
    if (location.state.type === "admin") {
      setType("admin");
      const details = await dispatch(getAdminDetails(location.state.id));
      setSelected(details);
    } else {
      setType("user");
      const details = await dispatch(getUserDetails(location.state.id));
      setSelected(details);
    }
  }, [location]);
  console.log(selected);

  const columns = [
    {
      title: type === "admin" ? "Customer Name" : "Restaurant Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Order Date",
      dataIndex: "date",
    },
    {
      title: "Order Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.length - b.amount.length,
    },
    {
      title: "Details",
      dataIndex: "details",
    },
  ];

  return (
    <Layout>
      <div className="admin-details">
        <Title level={2}>Selected {type === "admin" ? "Admin" : "User"}</Title>
        <Row gutter={[10, 0]}>
          <Col xs={24} md={12} lg={8}>
            <p>
              <span className="admin-detail">Name : </span>
              {type === "admin"
                ? userInfo?.name
                : // selected && selected[0].admin.name
                  userInfo?.name}
            </p>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <p>
              <span className="admin-detail">City : </span>
              {type === "admin"
                ? userInfo?.city
                : //  selected && selected[0].admin.city
                  userInfo?.city}
            </p>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <p>
              <span className="admin-detail">Suburb : </span>
              {type === "admin"
                ? userInfo?.suburb
                : // selected && selected[0].admin.suburb
                  userInfo?.suburb}
            </p>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <p>
              <span className="admin-detail">Email : </span>
              {type === "admin"
                ? userInfo?.email
                : // selected && selected[0].admin.email
                  userInfo?.email}
            </p>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <p>
              <span className="admin-detail">Contact : </span>
              {type === "admin"
                ? userInfo?.contactNumber
                : // selected && selected[0].admin.contactNumber
                  userInfo?.mobile}
            </p>
          </Col>
        </Row>
        <Title level={2}>Orders</Title>
        <Table
          columns={columns}
          dataSource={
            selected &&
            selected.length > 0 &&
            selected.map((order, i) => {
              // console.log(order);
              return {
                key: order._id,
                name: order.admin ? order.admin.name : "",
                date: moment(order.createdAt).format("MMM Do YY"),
                amount: order.amount,
                details: <ViewDetails orderDetails={order} type={type} />,
              };
            })
          }
          loading={selected === null ? true : false}
          scroll={{ x: 500 }}
          style={{ width: "100%" }}
          pagination={{
            // defaultPageSize: 10,
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
        />
      </div>
    </Layout>
  );
};

export default AdminDetails;
