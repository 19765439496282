import { Menu } from "antd";

import { FaUsers, FaUserShield, FaUserPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const LayoutMenu = ({ active }) => {
  return (
    <Menu theme="dark" defaultSelectedKeys={active} mode="inline">
      <Menu.Item key="all admins" icon={<FaUserShield />}>
        <Link to="/">All Admins</Link>
      </Menu.Item>
      <Menu.Item key="all users" icon={<FaUsers />}>
        <Link to="/all-users">All Users</Link>
      </Menu.Item>

      <Menu.Item key="create" icon={<FaUserPlus />}>
        <Link to="/create-admin">Create Admin</Link>
      </Menu.Item>
    </Menu>
  );
};

export default LayoutMenu;
