import * as userTypes from "../types/userTypes";

const initialState = {
  allUsers: [],
  users: [],
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case userTypes.GET_ALL_USERS:
      return {
        ...state,
        allUsers: payload,
      };
    case userTypes.SEARCH_USER:
      let searchedUser = payload;
      let newUser = state.allUsers.filter((user) => {
        return user.name.toLowerCase().includes(searchedUser);
      });
      return {
        ...state,
        allUsers: newUser,
      };
    case userTypes.GET_USERS:
      return {
        ...state,
        users: payload,
      };

    default:
      return state;
  }
};

export default userReducer;
