import axios from "axios";

import { url, headers } from "../../constants";
import * as adminTypes from "../types/adminTypes";
import swal from "sweetalert";

export const forgetPassword = (payload, history) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/forget-password`, payload);
    if (
      res &&
      res.data.message === "Recovery Code has been sent to your Email"
    ) {
      console.log(res);
      swal("", res.data.message, "success");
      localStorage.setItem("userType", payload.userType);
      localStorage.setItem("email", payload.email);
      history.push("/verify-code");
    } else {
      swal("", res.data.message, "info");
    }
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};

export const verifyCode = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/verify-code`, payload);
    if (res && res.data.message === "Code Verified") {
      console.log(res);
      swal("", `${res.data.message},Now you can set new password`, "success");
      localStorage.setItem("recoveryToken", res.data.recoveryToken);

      return { isVerified: true };
    } else {
      swal("", `${res.data.message}`, "error");
    }
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};
export const changePassword = (payload, history) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/user/change-password-forget`, payload);
    if (res) {
      console.log(res);
      swal("", `${res.data.message}`, "success").then(() => {
        history.replace("/login");
        localStorage.removeItem("recoveryToken");
        localStorage.removeItem("email");
        localStorage.removeItem("userType");
      });

      return { isVerified: true };
    }
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.message || "server error",
      icon: "error",
    });
  }
};

export const getAllAdmins = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/superAdmin/get-all-admins`, {
      headers,
    });
    console.log(res.data);
    if (res) {
      dispatch({
        type: adminTypes.GET_ALL_ADMINS,
        payload: res.data.admins,
      });
      // return res.data.admins;
    }
  } catch (err) {
    console.log(err);
  }
};
export const getAdmins = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/superAdmin/get-admins`, {
      headers,
    });
    console.log(res.data);

    if (res) {
      dispatch({
        type: adminTypes.GET_ADMINS,
        payload: res.data.admins,
      });
      // return res.data.admins;
    }
  } catch (err) {
    console.log(err);
  }
};
export const getAdminDetails = (admin) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${url}/superAdmin/get-admin-details`,
      {
        admin,
      },
      {
        headers,
      }
    );
    if (res) {
      console.log(res);
      return res.data.orders;
    }
  } catch (err) {
    console.log(err);
  }
};

export const searchAdmin = (payload) => (dispatch) => {
  console.log(payload);
  if (payload === "") {
    dispatch(getAllAdmins());
  } else {
    dispatch({
      type: adminTypes.SEARCH_ADMIN,
      payload: payload,
    });
  }
};

export const createAdmin = (payload) => async (dispatch) => {
  console.log(payload);
  try {
    const res = await axios.post(`${url}/superAdmin/create-admin`, payload, {
      headers,
    });
    if (res) {
      console.log(res);
      swal({
        title: res.data.message,
        icon: "success",
      });
    }
    console.log(res);
  } catch (err) {
    console.log(err);
    swal({
      title: err?.response?.data?.msg || "server error",
      icon: "error",
    });
  }
};

// get cities and countries

export const getAllCountries = () => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/city/get-countries`);
      if (res) {
        return res.data.countries;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCitiesbyCountry = (country) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${url}/city/get-cities`, { country });
      if (res) {
        return res.data.cities;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCurrencies = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${url}/city/get-all-currencies`);
      if (res) {
        return res.data;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const deactivateAdmin = (adminId) => {
  console.log(adminId);
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${url}/superAdmin/deactivate-admin`,
        {
          adminId,
        },
        {
          headers,
        }
      );
      if (res) {
        dispatch(getAllAdmins());
        swal("", res.data.message, "success");
        // window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };
};
