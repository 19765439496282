import Layout from "../layout/DashboardLayout";
import { Table, Input, Typography, Button } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdmins,
  getAllAdmins,
  searchAdmin,
  deactivateAdmin,
} from "../redux/actions/adminActions";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const AllAdmins = () => {
  const searchTable = (e) => {
    console.log(e);
    dispatch(searchAdmin(e.target.value));
  };

  const history = useHistory();

  const admins = useSelector((state) => state.adminReducer.allAdmins);
  const adminEndorsement = useSelector((state) => state.adminReducer.admins);

  const [adminsData, setAdminData] = useState();

  const [isData, setIsData] = useState(null);

  const dispatch = useDispatch();
  const columns = [
    {
      title: "S.No",
      dataIndex: "count",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => a.city.length - b.city.length,
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) => a.country.length - b.country.length,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Phone Number",
      dataIndex: "number",
    },
    {
      title: "Endorsements",
      dataIndex: "endorsements",
      sorter: (a, b) => a.endorsements - b.endorsements,
    },
    {
      title: "View Details",
      dataIndex: "view",
    },
    {
      title: "Active/Deactive",
      dataIndex: "statusChange",
    },
  ];

  // console.log(adminsData && adminsData[2]);

  useEffect(() => {
    dispatch(getAllAdmins());
    dispatch(getAdmins());
  }, []);

  useEffect(async () => {
    let tempArr = [];
    if (
      admins &&
      admins.length > 0 &&
      adminEndorsement &&
      adminEndorsement.length > 0
    ) {
      admins &&
        admins.map((admin) => {
          adminEndorsement &&
            adminEndorsement.map((endorse) => {
              if (admin._id === endorse._id) {
                tempArr.push({ ...admin, endorsement: endorse.endorsement });
              }
            });
          tempArr.push({ ...admin, endorsement: 0 });
        });
    } else {
      admins &&
        admins.map((admin) => {
          tempArr.push({ ...admin, endorsement: 0 });
        });
    }

    let tempId = [];
    let finalResult = [];
    tempArr.map((arr) => {
      const index = tempId.indexOf(arr._id);
      if (index === -1) {
        tempId.push(arr._id);
        finalResult.push(arr);
      }
    });

    setAdminData(finalResult);
  }, [admins]);

  setTimeout(() => {
    setIsData(adminsData);
  }, 2000);

  return (
    <Layout active="all admins">
      <Typography.Title level={2}>All Admins</Typography.Title>
      <div>
        <Input
          placeholder="search by name"
          className="search-bar"
          allowClear
          onChange={(e) => searchTable(e)}
        />
        <Table
          columns={columns}
          dataSource={
            adminsData &&
            adminsData.length > 0 &&
            adminsData.map((admin, i) => {
              // console.log(admin);
              return {
                key: admin._id,
                count: i + 1,
                name: admin.name,
                email: admin.email,
                city: admin.city,
                status: admin.active ? (
                  <span style={{ color: "#1DB700" }}>Active</span>
                ) : (
                  <span style={{ color: "red" }}>Inactive</span>
                ),
                country: admin.country,
                number: admin.contactNumber,
                endorsements: admin.endorsement,
                // view: <ViewDetails id={admin._id} type="admin" />,
                view: (
                  <Button
                    onClick={() =>
                      history.push({
                        pathname: "/details",
                        state: { id: admin._id, type: "admin", details: admin },
                      })
                    }
                    type="primary"
                  >
                    view
                  </Button>
                ),
                statusChange: admin.active ? (
                  <Button
                    type="primary"
                    onClick={() => dispatch(deactivateAdmin(admin._id))}
                  >
                    Make Inactive
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => dispatch(deactivateAdmin(admin._id))}
                  >
                    Make Active
                  </Button>
                ),
              };
            })
          }
          // loading={adminsData && adminsData.length > 0 ? false : true}
          // onChange={onTableChange}
          // loading={loading}
          loading={isData === null ? true : false}
          scroll={{ x: 600 }}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
        />
      </div>
    </Layout>
  );
};

export default AllAdmins;
