import { useState } from "react";
import { Form, Input, Button } from "antd";
import { Link, useHistory, Redirect } from "react-router-dom";
// import swal from 'sweetalert'

import logo from "../assets/qrlogo.png";
import axios from "axios";
import { url } from "../constants";
import swal from "sweetalert";

const Login = () => {
  //   const { adminId, tableId } = useParams();
  //   console.log(adminId, tableId);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  //   const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    console.log(values);
    // dispatch(userLogin(values, history, adminId, tableId));
    if (values.email !== "" && values.password !== "") {
      const payload = {
        email: values.email,
        password: values.password,
      };
      try {
        let res = await axios.post(`${url}/superAdmin/login`, payload);
        if (res) {
          console.log(res);
          localStorage.setItem("superAdminId", res.data.userId);
          localStorage.setItem("token", res.data.token);
          swal({
            title: "Login Success",
            icon: "success",
          }).then(() => {
            setLoading(false);
            history.replace("/");
          });
        }
      } catch (error) {
        console.log(error);
        console.log(error.response.data);
        console.log(error.message);
        swal({
          title: "Incorrect Email Or Password",
          icon: "error",
        });
        setLoading(false);
      }
    } else {
      swal({
        title: "Fields Validation Error",
        icon: "error",
      });
    }
  };

  if (localStorage.hasOwnProperty("superAdminId")) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="signin">
        <Link to="/">
          <img src={logo} alt="logo" width="auto" height="100px" />
          {/* <p>QRTREAT</p> */}
        </Link>
        <Form
          name="login"
          className="login-form"
          layout="vertical"
          onFinish={onFinish}
        >
          <h2>Admin Login</h2>
          <Form.Item name="email" label="Email">
            <Input autoComplete="off" placeholder="Enter Email..." />
          </Form.Item>
          <Form.Item name="password" label="Password">
            <Input.Password type="password" placeholder="Password..." />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Login
            </Button>
          </Form.Item>

          <div className="bottom-links">
            <p>
              <Link to="/forget-password" className="login-forgot">
                Forgot password?
              </Link>
            </p>
          </div>
        </Form>
      </div>
    );
  }
};

export default Login;
