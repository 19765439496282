import Layout from "../layout/DashboardLayout";
import { Table, Input, Typography, Button } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  getUsers,
  searchUser,
  deactivateUser,
} from "../redux/actions/userActions";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const AllUsers = () => {
  const searchTable = (e) => {
    dispatch(searchUser(e.target.value));
  };

  const dispatch = useDispatch();

  const history = useHistory();

  const users = useSelector((state) => state.userReducer.allUsers);
  const userSpending = useSelector((state) => state.userReducer.users);

  const [userData, setUserData] = useState();

  const [isData, setIsData] = useState(null);

  const columns = [
    {
      title: "created At",
      dataIndex: "createdAt",
      width: 200,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: {
        compare: (a, b) => a.city.length - b.city.length,
        multiple: 3,
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: {
        compare: (a, b) => a.country.length - b.country.length,
        multiple: 2,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Phone Number",
      dataIndex: "number",
      // sorter: {
      //   compare: (a, b) => a.number - b.number,
      //   multiple: 1,
      // },
    },
    {
      title: "Spending",
      dataIndex: "spending",
      sorter: (a, b) => a.spending - b.spending,
    },
    {
      title: "View Details",
      dataIndex: "view",
    },
    {
      title: "Active/Deactive",
      dataIndex: "statusChange",
    },
  ];

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getUsers());
  }, []);

  useEffect(async () => {
    let tempArr = [];
    if (users && users.length > 0 && userSpending && userSpending.length > 0) {
      users &&
        users.map((user) => {
          userSpending &&
            userSpending.map((spend) => {
              if (user._id === spend._id) {
                tempArr.push({ ...user, spending: spend.spending });
              }
            });
          tempArr.push({ ...user, spending: 0 });
        });
    } else {
      users &&
        users.map((user) => {
          tempArr.push({ ...user, spending: 0 });
        });
    }

    let tempId = [];
    let finalResult = [];
    tempArr.map((arr) => {
      const index = tempId.indexOf(arr._id);
      if (index === -1) {
        tempId.push(arr._id);
        finalResult.push(arr);
      }
    });

    setUserData(finalResult);
  }, [users]);

  setTimeout(() => {
    setIsData(userData && userData);
  }, 2000);

  return (
    <Layout active="all users">
      <Typography.Title level={2}>All Users</Typography.Title>
      <div>
        <Input
          placeholder="search by name"
          className="search-bar"
          allowClear
          onChange={(e) => searchTable(e)}
        />
        <Table
          columns={columns}
          dataSource={
            userData &&
            userData.length > 0 &&
            userData.map((user, i) => {
              return {
                key: user._id,
                createdAt: moment(user.createdAt).format("MMM Do YY"),
                name: user.name,
                email: user.email,
                city: user.city,
                country: user.country,
                number: user.mobile,
                spending: user.spending,
                // view: <ViewDetails id={user._id} type="user" />,
                view: (
                  <Button
                    onClick={() =>
                      history.push({
                        pathname: "/details",
                        state: { id: user._id, type: "user", details: user },
                      })
                    }
                    type="primary"
                  >
                    view
                  </Button>
                ),
                status: user.active ? (
                  <span style={{ color: "#1DB700" }}>Active</span>
                ) : (
                  <span style={{ color: "red" }}>Inactive</span>
                ),
                statusChange: user.active ? (
                  <Button
                    type="primary"
                    onClick={() => dispatch(deactivateUser(user._id))}
                  >
                    Make Inactive
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => dispatch(deactivateUser(user._id))}
                  >
                    Make Active
                  </Button>
                ),
              };
            })
          }
          // loading={userData && userData.length > 0 ? false : true}
          // loading={loading}
          loading={isData === null ? true : false}
          scroll={{ x: 600 }}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
        />
      </div>
    </Layout>
  );
};

export default AllUsers;
