import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import AdminDetails from "./pages/AdminDetails";
import AllAdmins from "./pages/allAdmins";

// dashboard pages
import AllUsers from "./pages/AllUsers";
import CreateAdmin from "./pages/CreateAdmin";
import ForgetPassword from "./pages/ForgetPassword";
import Login from "./pages/Login";
import VerifyCode from "./pages/VeirfyCode";

import PrivateRoutes from "./PrivateRoutes";

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <PrivateRoutes path="/" exact component={AllAdmins} />
          <Route path="/login" exact component={Login} />
          <PrivateRoutes path="/all-users" exact component={AllUsers} />
          <PrivateRoutes path="/create-admin" exact component={CreateAdmin} />
          <PrivateRoutes path="/details" exact component={AdminDetails} />
          <Route path="/forget-password" exact component={ForgetPassword} />
          <Route path="/verify-code" exact component={VerifyCode} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
