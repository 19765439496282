import { Typography, Form, Input, Button, } from "antd";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { verifyCode } from "../../redux";

const CodeVerification = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const { Title } = Typography;
  const onFinish = async (values) => {
    setLoading(true);
    console.log(values);
    const payload = {
      code: values.code,
      userType: localStorage.getItem("userType"),
      email: localStorage.getItem("email"),
    };
    const isVerified = await dispatch(verifyCode(payload));
    setLoading(false);
    setVerified(isVerified);
  };

  return (
    <>
      <Title level={4} className="title">
        Verify Code
      </Title>
      <Form
        name="normal_login"
        className="login-form"
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="code"
          label="Code"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input placeholder="enter your code..." disabled={verified} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
            disabled={verified}
          >
            {verified ? "verified" : "verify"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CodeVerification;
